import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Step1 from "./step1.jpg"
import Step2 from "./step2.jpg"
import Step3 from "./step3.jpg"
import Step4 from "./step4.jpg"
import Step5 from "./step5.jpg"
import Step6 from "./step6.jpg"
import Step7 from "./step7.png"

const Kroki = () => {
  const settings = {
    adaptiveHeight: true,
    lazyLoad: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <article className="kroki">
      <Slider {...settings} className="slider">
        <article>
          <header>1| Jak otworzyć konto w C24?</header>

          <footer>
            <p>
              Założenie konta w C24 jest proste.
              <br />
              Przewijając w prawo poznasz kolejne kroki.{" "}
            </p>
            <p>
              Wejdź na stronę banku:
              <br />
              <a href="https://a.check24.net/misc/click.php?pid=497276&aid=341&deep=c24bank&cat=14" target="_blank" rel="noopener noreferrer">
                https://www.c24.de/antrag-starten
              </a>
            </p>
            <p style={{ marginBottom: ".5rem" }}>lub kliknij w baner poniżej:</p>
            <a href="https://a.check24.net/misc/click.php?pid=497276&aid=342&deep=c24bank&cat=14" target="_blank" rel="noopener noreferrer" style={{ display: "inline" }}>
              <img src="https://a.check24.net/misc/view.php?pid=497276&aid=342&cat=14" width="300" height="250" className="baner" alt="c24 baner" loading="lazy" />
            </a>

            <p style={{ marginTop: ".5rem" }}>
              <i>To link partnerski. Nic Cię nie kosztuje, a pomaga rozwijać serwis.</i>
            </p>
          </footer>
        </article>

        <article>
          <header>2| Telefon i mail</header>
          <img src={Step1} alt="Telefon i mail" loading="lazy" />
          <footer>
            <p>
              <mark>Vorwahl</mark>: <span data-tooltip="+49 Niemcy, +48 Polska">kierunkowy kraju</span>
            </p>
            <p>
              <mark>Mobilnummer</mark>: <span data-tooltip="numer podajemy bez poprzedzającego 0">numer telefonu</span>
            </p>
            <p>
              <mark>E-Mail-Adresse</mark>: adres mailowy
            </p>
          </footer>
        </article>

        <article>
          <header>3| Dane osobowe</header>
          <img src={Step2} alt="Dane osobowe" loading="lazy" />
          <footer>
            <p>
              <mark>Frau / Herr</mark>: pani / pan
            </p>
            <p>
              <mark>Vorname</mark>: imię
            </p>
            <p>
              <mark>Nachname</mark>: <span data-tooltip="Podaj dane osobowe analogicznie jak w dowodzie osobistym / paszporcie.">nazwisko</span>
            </p>
          </footer>
        </article>

        <article>
          <header>4| Data urodzenia i obywatelstwo</header>
          <img src={Step3} alt="Data urodzenia i obywatelstwo" loading="lazy" />
          <footer>
            <p>
              <mark>Geburtsdatum</mark>: data urodzenia
            </p>
            <p>
              <mark>Geburtsort</mark>: miejsce urodzenia
            </p>
            <p>
              <mark>Staatsangehörigkeit</mark>: <span data-tooltip="polski obywatel może założyć konto bez przeszkód">obywatelstwo</span>
            </p>
          </footer>
        </article>

        <article>
          <header>5| Miejsce zamieszkania</header>
          <img src={Step4} alt="Miejsce zamieszkania" loading="lazy" />
          <footer>
            <p>
              <mark>Postleitzahl</mark>: kod pocztowy
            </p>
            <p>
              <mark>Wohnort</mark>: miejscowość
            </p>
            <p>
              <mark>Straße</mark>: ulica
            </p>
            <p>
              <mark>Hausnr.</mark>: numer domu
            </p>
            <p>
              <mark>Adresszusatz</mark>: <span data-tooltip="dodatkowa linia adresu">ew. adres cd.</span>
            </p>
            <p>
              <mark>Straße</mark>: ulica
            </p>
          </footer>
        </article>

        <article>
          <header>6| Informacje podatkowe</header>
          <img src={Step5} alt="Miejsce zamieszkania" loading="lazy" />
          <footer>
            <p>
              <mark>Obowiązek podatkowy</mark>: <span data-tooltip="warunek konieczny">Niemcy</span>
            </p>
            <p>
              <mark>Steuer-ID</mark>: <span data-tooltip="wprowadzenie nieobowiązkowe na tym etapie rejestracji; bank pozyska z Urzędu Skarobwego">numer podatkowy</span>
            </p>
            <p>
              <mark>weitere Steuerländer</mark>: <span data-tooltip="wybór z listy krajów">inne rezydencje podatkowe</span>
            </p>
            <p>
              <mark>Beruf</mark>: <span data-tooltip="zatrudniony, samozatrudniony (konto do użytku prywatnego) lub inna forma zatrudnienia">forma zatrudnienia</span>
            </p>
          </footer>
        </article>

        <article>
          <header>7| Pobierz aplikację</header>
          <img src={Step6} alt="Ściągnij aplikację" loading="lazy" />
          <footer>
            <p>
              <mark>App herunterladen</mark>: <span data-tooltip="aplikacja niezbędna do ukończenia identyfikacji, do pobrania z Play/App Store">pobierz aplikację</span>
            </p>
          </footer>
        </article>

        <article>
          <header>8| Legitymacja</header>
          <img src={Step7} alt="Legitymacja" loading="lazy" />
          <footer>
            <p>
              Tożsamość można potwierdzić za pomocą:
              <br /> (1) <span data-tooltip="własnego, niemieckiego">innego konta</span>,
              <br /> (2) <span data-tooltip="ze smartfona poprzez aplikację C24">połączenia</span> wideo z konsultantem
              <br /> (3) lub w <span data-tooltip="Deutsche Post">placówce</span> pocztowej
            </p>

            <p>Polecam wybrać pocztę. Okaż kupon POSTIDENT w aplikacji C24 i dokument tożsamości: dowód osobisty lub paszport. Gotowe.</p>
          </footer>
        </article>
      </Slider>
    </article>
  )
}

export default Kroki
