import React from "react"

import Header from "./Header"
import Bank from "./Bank"
import Konto from "./Konto"
import Karta from "./Karta"
import Aplikacja from "./Aplikacja"
import Wymogi from "./Wymogi"
import Baner from "./Baner"
import Kroki from "./Kroki"

export default function Check() {
  return (
    <>
      <Header />
      <Konto />
      <Karta />
      <Aplikacja />
      <Wymogi />
      <Kroki />
      <Bank />
      <Baner />
    </>
  )
}
