import { Component } from "react"

export default class Baner extends Component {
  render() {
    return (
      <div className="baner-wrapper">
        <a href="https://a.check24.net/misc/click.php?pid=497276&aid=337&deep=c24bank&cat=14" target="_blank" rel="noopener noreferrer">
          <img src="https://a.check24.net/misc/view.php?pid=497276&aid=337&cat=14" width="1023" height="60" className="baner" alt="c24 baner" loading="lazy" />
        </a>
        <p>
          <small>
            <cite>Miło mi jeśli pomogłem </cite>🙏
          </small>
        </p>
      </div>
    )
  }
}
