import { Component } from "react"

export default class Bank extends Component {
  render() {
    return (
      <article className="bank">
        <hgroup>
          <h2>Bank C24</h2>
          <p>bezpieczeństwo</p>
        </hgroup>

        <div className="bank-content">
          <p>C24 jest licencjowanym niemieckim bankiem należącym do grupy CHECK24, to fintech największego portalu porównawczego w Niemczech. Bankowi zaufało dotąd ponad 100'000 klientów.</p>
          <p>
            C24 podlega regulacjom i monitoringowi Federalnego Urzędu Nadzoru Finansowego (
            <a href=" https://www.bafin.de/DE/DieBaFin/diebafin_node.html" target="_blank" rel="noopener noreferrer">
              BaFin
            </a>
            ). Pieniądze klientów zabezpieczone są{" "}
            <a href="https://www.bundesbank.de/de/aufgaben/bankenaufsicht/einzelaspekte/einlagensicherung/einlagensicherung-597886" target="_blank" rel="noopener noreferrer">
              ustawową
            </a>{" "}
            ochroną depozytów.
          </p>
          <p>C24 dba o cyberbezpieczeństwo: wdrożył szyfrowane połączenia, uwierzytelnianie wieloskładnikowe, powiadomienia push dla transakcji, stałe monitorowanie podejrzanych aktywności.</p>
        </div>
      </article>
    )
  }
}
