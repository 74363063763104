import React, { useEffect } from "react"
import ReactGA from "react-ga4"
import { Helmet } from "react-helmet-async"
import { Route, Routes } from "react-router-dom"
import ScrollUpButton from "react-scroll-up-button"

import "./App.css"

import Nawigacja from "./Nawigacja"
import Check from "./Check"
import Impresum from "./Impresum"

ReactGA.initialize("G-9JCXPRFWM8")

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "landing page" })
  }, [])

  return (
    <main className="container-fluid overflow">
      <Helmet>
        <html lang="pl" />
        <link hreflang="x-default" href="https://bankowy.de" rel="alternate" />
        <title>Konto C24 krok po kroku || bankowy.de </title>
        <meta name="description" content="Zakładamy konto C24 krok po kroku." />
        <meta name="keywords" content="darmowe konto bankowe w Niemczech przez internet bez meldunku, niemieckie konto dla polaka, bank niemcy, konto osobiste niemcy, korzystny rachunek w euro bez opłat" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bankowy.de" />
        <meta charset="UTF-8" />
        <meta name="author" content="bankowy.de" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.bankowy.de" />
      </Helmet>

      <ScrollUpButton
        ShowAtPosition={150}
        style={{
          backgroundColor: "transparent",
          width: "3.5rem",
          height: "3.5rem",
        }}
      />

      <Nawigacja />

      <Routes>
        <Route path="/" element={<Check />} />
        <Route path="/impresum" element={<Impresum />} />
      </Routes>
    </main>
  )
}

export default App
