import "./App.css"

export default function Header() {
  return (
    <header className="wstep">
      <blockquote>
        <p>
          Interesują mnie tylko konta <strong>darmowe</strong>. Nie chcę płacić za przelewy, obługę karty płatniczej czy wyciąganie gotówki z bankomatów. Nie chcę wymogu regularnych wpłat. Oczekuję oprocentowania kapitału.
        </p>
        <hr />
        <p style={{ textAlign: "center" }}>
          Z kontem{" "}
          <strong>
            {" "}
            <a href="https://a.check24.net/misc/click.php?pid=497276&aid=18&deep=c24bank&cat=14" target="_blank" rel="noopener noreferrer">
              C24 Smart
            </a>
          </strong>{" "}
          to możliwe:
        </p>
      </blockquote>
    </header>
  )
}
