import { Component } from "react"
import Mastercard from "./mastercard.png"

export default class Karta extends Component {
  render() {
    return (
      <article className="karta">
        <details open>
          <summary>
            + karta debetowa Mastercard <img src={Mastercard} alt="Mastercard" loading="lazy" />
          </summary>
          <table>
            <tbody>
              <tr>
                <td>Wypłaty z bankomatów</td>
                <td data-tooltip="z bankomatów z logo Mastercard, Maestro, Cirrus lub girocard">4 darmowe w miesiącu</td>
              </tr>
              <tr>
                <td>Cashback</td>
                <td data-tooltip="aktywacja cashback w aplikacji">od 0,05% do 2,5%</td>
              </tr>
              <tr>
                <td>Płatności zbliżeniowe</td>
                <td data-tooltip="aktywacja w aplikacji">z Apple Pay i Google Pay</td>
              </tr>
              <tr>
                <td>+ Karta girocard (karta EC)</td>
                <td data-tooltip="do zamówienia w aplikacji">darmowa na życzenie</td>
              </tr>
              <tr>
                <td>+ Karta wirtualna</td>
                <td data-tooltip="aktywacja w aplikacji">darmowa na życzenie</td>
              </tr>
            </tbody>
          </table>
        </details>
      </article>
    )
  }
}
