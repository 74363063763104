import React, { useState } from "react"
import "./App.css"

import Datenschutz from "./Datenschutz"
import Prywatnosc from "./Prywatnosc"

const Impresum = () => {
  const [lang, setLang] = useState("polski")

  const handleLang = (tab) => {
    setLang(tab)
  }

  return (
    <div className="impresum-wrapper">
      <h1>Impresum</h1>

      <fieldset>
        <input type="radio" id="deutsch" checked={lang === "deutsch"} name="language" onClick={() => handleLang("deutsch")} />
        <label htmlFor="deutsch">deutsch</label>
        <input type="radio" id="polski" checked={lang === "polski"} name="language" onClick={() => handleLang("polski")} />
        <label htmlFor="polski">polski</label>
      </fieldset>
      {lang === "deutsch" ? <Datenschutz /> : <Prywatnosc />}
    </div>
  )
}

export default Impresum
