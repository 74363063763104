import { Component } from "react"

export default class Aplikacja extends Component {
  render() {
    return (
      <article className="aplikacja">
        <details open>
          <summary>+ aplikacja / dostęp online</summary>
          <table>
            <tbody>
              <tr>
                <td>Multibanking</td>
                <td data-tooltip="obsługa wielu banków z poziomu jednej aplikacji">obsługa wielu banków</td>
              </tr>
              <tr>
                <td>Analiza wydatków</td>
                <td data-tooltip="np. czynsz, prąd, ubezpieczenia, itp.">grupowanie wydatków</td>
              </tr>
              <tr>
                <td>Limity na karcie</td>
                <td data-tooltip="przejrzystość i wygoda">ustalane w aplikacji</td>
              </tr>
              <tr>
                <td>Blokada karty</td>
                <td data-tooltip="ekspresowa realizacja">ustalane w aplikacji</td>
              </tr>
              <tr>
                <td>Odczyt do przelewu</td>
                <td data-tooltip="aktywacja w aplikacji">ze zdjęcia, kodu QR lub z przesłanego pliku</td>
              </tr>
              <tr>
                <td>Przeniesienie konta</td>
                <td data-tooltip="zautomatyzowane przeniesienie konta">darmowe</td>
              </tr>
              <tr>
                <td>Powiadomienia push</td>
                <td data-tooltip="z opcją personalizowania komunikatów dotyczących transakcji, wypłat z bankomatu, itp.">w czasie rzeczywistym o aktywnościach na koncie</td>
              </tr>
            </tbody>
          </table>
        </details>
      </article>
    )
  }
}
