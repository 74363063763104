import { Component } from "react"

export default class Wymogi extends Component {
  render() {
    return (
      <article className="wymogi">
        <hgroup>
          <h2>Wymogi</h2>
        </hgroup>
        <article>
          <header>
            Konto{" "}
            <a href="https://a.check24.net/misc/click.php?pid=497276&aid=18&deep=c24bank&cat=14" target="_blank" rel="noopener noreferrer">
              C24 Smart
            </a>{" "}
            możesz założyć, jeśli:
          </header>
          <ul>
            <li data-tooltip="a więc także dla polskich obywateli">jesteś pełnoletnim obywatelem UE</li>
            <li data-tooltip="zamieszkanie poświadczone prez 'Meldebescheinigung'">mieszkasz i płacisz podatki w Niemczech</li>
          </ul>
        </article>
        <article>
          <header>Na potrzeby rejestracji konta przygotuj:</header>
          <ul>
            <li data-tooltip="można legitymować się polskimi dokumentami">dowód osobisty lub paszport</li>
            <li data-tooltip="smartfon potrzebny też do finalizacji rejestracji">smartfon do obsługi aplikacji</li>
          </ul>
        </article>
      </article>
    )
  }
}
