import React, { useState } from "react"
import { Link } from "react-router-dom"

export default function Nawigacja() {
  const [activeLink, setActiveLink] = useState("anzeige")

  const handleLinkClick = (tab) => {
    setActiveLink(tab)
  }

  return (
    <nav aria-label="breadcrumb" className="nawigacja">
      <ul>
        <li>
          <Link to="/" className={activeLink === "anzeige" ? "active" : ""} onClick={() => handleLinkClick("anzeige")}>
            Anzeige
          </Link>
        </li>
        <li>
          <Link to="/impresum" className={activeLink === 2 ? "active" : ""} onClick={() => handleLinkClick(2)}>
            Impresum
          </Link>
        </li>
      </ul>
    </nav>
  )
}
