import { Component } from "react"

export default class Konto extends Component {
  render() {
    return (
      <article className="konto">
        <hgroup>
          <h2>C24 Smart</h2>
        </hgroup>
        <details open>
          <summary>darmowe konto osobiste</summary>
          <table>
            <tbody>
              <tr>
                <td>Prowadzenie konta</td>
                <td data-tooltip="bez opłat za przelewy czy posiadanie konta">0 €</td>
              </tr>
              <tr>
                <td>Oprocentowanie konta</td>
                <td
                  data-tooltip="Girokonto: do 50.000€, oprocentowanie naliczane kwartalnie ||
Tagesgeld: do 100.000€, oprocentowanie naliczane miesięcznie"
                >
                  2,5% w skali roku
                </td>
              </tr>
              <tr>
                <td>Stałe zasilanie konta</td>
                <td data-tooltip="np. miesięczne przelewy wynagrodzenia">niewymagane</td>
              </tr>
              <tr>
                <td>Subkonta</td>
                <td data-tooltip="każde subkonto (tzw. 'pocket') z własnym IBAN">cztery</td>
              </tr>
              <tr>
                <td>Zmiana modelu konta</td>
                <td data-tooltip="zmiana możliwa w aplikacji">w dowolnym momencie</td>
              </tr>
              <tr>
                <td>Saldo ujemne</td>
                <td data-tooltip="uwarunkowana zdolnością kredytową">7,49% w skali roku </td>
              </tr>
            </tbody>
          </table>
        </details>
      </article>
    )
  }
}
